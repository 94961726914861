<template>
  <div class="home">
    <SidebarMenu @go-section="goToSection"/>
    
    <HeaderSection @go-section="goToSection" />
    <main>
      <SkillsSection ref="skills"/>
      <ExperienceSection ref="experience"/>
      <WorkSection @go-section="goToSection" ref="work"/>
      <AboutSection ref="about"/>
      <ContactSection ref="contact"/>
    </main>

    <CursorEffectComponent />
  </div>
</template>

<script>

// @ is an alias to /src
//import sidebar
import SidebarMenu from '@/components/SidebarMenu.vue'

//import sections
import HeaderSection from '@/components/Sections/HeaderSection.vue'
import SkillsSection from '@/components/Sections/SkillsSection.vue'
import ExperienceSection from '@/components/Sections/ExperienceSection.vue'
import WorkSection from '@/components/Sections/WorkSection.vue'
import AboutSection from '@/components/Sections/AboutSection.vue'
import ContactSection from '@/components/Sections/ContactSection.vue'

//import cursor trail effect
import CursorEffectComponent from '@/components/CursorEffectComponent.vue'

export default {
  name: 'HomeView',
  components: {
    SidebarMenu,
    HeaderSection,
    SkillsSection,
    ExperienceSection,
    WorkSection,
    AboutSection,
    ContactSection,
    CursorEffectComponent
  },
  methods: {
    goToSection (value) {
      const top = value === 'home' ? 0 : this.getOffsetTop(this.$refs[(value)].$el);
      window.scrollTo({
        top: top,
        left: 0,
        behavior: 'smooth'
      });
    },
    getOffsetTop (element) {
      let offsetTop = 0;
      while(element) {
        offsetTop += element.offsetTop;
        element = element.offsetParent;
      }
      return offsetTop;
    }
  }
}
</script>

<style scoped>

</style>
