<template>
  <div>
    <header id="home" class="section">
          
          <div class="particles">
            <ParticlesComponent/>
          </div>

          <div class="header-shadow"></div>
  
          <div class="header-header">
              <div class="header-head">
                <div class="profile">
                  <img src="@/assets/img/portraits/webp/rilind-tasholli-header-portrait.webp" alt="Rilind Tasholli Full-Stack Developer">
                </div>
                  <img 
                    :class="!isMobileOrTablet ? 'hello-icon wow animate__zoomInDown' : 'hello-icon wow animate__zoomInDown'" 
                    data-wow-duration="1.85s" 
                    src="@/assets/img/icons/other/webp/hello-icon.webp"
                    alt="Hello Chat Icon"
                  />
              </div>
              
              <div class="header-body">
                  <h1><span class='iam'>I'm </span>Rilind Tasholli</h1>
                  <span class='lime-title'>[Full-Stack Developer]</span>
                  <!-- <p class='about'>
                    I am a hard worker and self-motivated person with a strong <span>eye</span> for details, who is always looking for opportunities to showcase a number of skills I have. I am a quick learner, very well organized and take pride in my work.
                  </p> -->
                  <!-- <p class="about">
                    I'm a self-motivated hard worker with a strong eye for detail, always seeking to improve and showcase my skills. 
                    I'm a quick learner and take pride in delivering high-quality work, ensuring that every project I work on is completed to the best of my ability.
                  </p> -->
                  <p class="about">
                    I'm a hard worker and self-motivated person with a strong <span @click="handleEyeTextClick">eye</span> for detail, always seeking to improve and showcase my skills. 
                    I'm a quick learner, well-organized and take pride in my work, ensuring that every project or task is completed to the best of my ability.
                  </p>

                  <div class="header-buttons">
                    <button @click="$emit('go-section', 'work')" class="secondary-btn">See my work</button>
                    <button @click="$emit('go-section', 'contact')" class="primary-btn">Contact me</button>
                  </div>
              </div>
  
          </div>
          <div class="header-curve"></div>
          
      </header>
      <!-- <div class="eye">
        <img class="eyeIcon" src="@/assets/img/eye.png"/>
    </div> -->
      <EyeComponent />
  </div>
</template>

<script>
import EyeComponent from '@/components/EyeComponent.vue'
import ParticlesComponent from "../ParticlesComponent.vue";

export default {
  components: {
    EyeComponent,
    ParticlesComponent
  },
  computed: {
    isMobileOrTablet: () => window.innerWidth < 1024,
  },
  methods: {
    handleEyeTextClick () {
      const eyeActive = localStorage.getItem('eyeActive') === 'true'

      const activateEyeBtnPopAnimation = [
        { transform: "scale(1)" },
        { transform: "scale(1.3)" },
        { transform: "scale(1)" },
        { transform: "scale(1.25)" },
        { transform: "scale(1)" }
      ];
      
      const eyeToggleBtn = document.querySelector('.eye-toggle-btn');

      console.log(eyeActive)

        if(!eyeActive) {
          eyeToggleBtn.animate(
          activateEyeBtnPopAnimation, {
            duration: 1000,
            iterations: 1,
          }
        );
      }

      
    }
  }
}
</script>

<style scoped>

  header {
    width: 100%;
    min-height: 80vh;
    padding: 4rem 0 180px 0;
    background-image: url("@/assets/img/background/webp/header-background-blur.webp");
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    /* border: dashed 2px red; */
    position: relative;
    
    /* border: dashed 2px red; */
  }

  .header-header {
    /* display: flex;
    flex-direction: column; */

    max-width: 645px;
    margin: 25px 0px 0 15vw;
    /* border: solid 1px blue; */
    z-index: 4;
    position: relative;
    animation: headerLoadAnimation ease 1.5s forwards;
    /* overflow: hidden; */
  }

  .header-header p {
    display: inline-block;
    line-height: 1.3em;
    /* padding-right: 1px; */
    margin: 10px 0;
  }


  .header-body{
    /* animation: ease headerLoadAnimation 1.5s 1.7s forwards;
    transform: scale(0); */

    text-align: left;
  }

  @keyframes headerLoadAnimation {
    0% {
      opacity: 0;
      transform: scale(0.5);
    }
    100% {
      opacity: 1;
      transform: scale(1);
    }
  }

  .header-shadow {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    /* max-width: 1800px; */
    height: 100%;
    background: linear-gradient(
      75deg,
      rgba(19, 22, 22, 0.98),
      rgba(19, 22, 22, 0.95) 52%,
      rgba(20, 20, 20, 0.86) 67%,
      rgba(24, 24, 24, 0.58) 100%
    );
    z-index: 2;
    opacity: 0.984;
    box-shadow: inset 130px -100px 120px rgba(21, 114, 91, 0.08);
  }

  .particles {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    /* border: dashed 1px red; */
    /* z-index: 2; */
    opacity: 1;
  }

  

  .header-curve {
    background-color: #18191a;
    /* background: linear-gradient(180deg, rgba(24, 25, 26, 0) 0%, rgba(24,25,26,1) 85%); */
    width: 100%;
    /* height: 0; */
    margin-top: 0px;
    position: absolute;
    bottom: -2px;
    z-index: 2;
    /* clip-path: polygon(100% 0, 0% 100%, 100% 100%); */
    clip-path: polygon(0 0, 0% 100%, 100% 100%);
    /* clip-path: polygon(50% 100%, 100% 0, 100% 100%, 0 100%, 0 0); */

    /* clip-path: polygon(0 0, 100% 88%, 100% 100%, 0% 100%); */

    /* animation: header-curve-animation ease 1.5s forwards; */
    height: 92px;
    /* transform: rotate(0deg); */
  }

  @keyframes header-curve-animation {
    from {
      height: 0px;
    }
    to {
      height: 115px;
    }
  }
  .header-head {
    display: flex;
    align-items: center;
    /* background-color: red; */
  }

  .profile  {
    /* width: 243px; */
    height: 243px;
    margin: 15px 15px 15px 0;
    border-radius: 50%;
    
    /* border: 3px solid rgba(19, 196, 155, 0.175); */
    /* overflow: hidden; */

    width: 0;
    transform: scale(0);

    animation: 
    ease profile-load-animation 0.8s 0.5s forwards,
    linear profile-pulse-animation 2.3s infinite;

    /* transform: scale(0);
    animation: ease profile-load-animation 1.3s 0.4s forwards; */
  }
  
  .profile img{
    width: 100%;
    height: 100%;
    border-radius: 50%;
    border: solid 8px transparent;
    object-position: center;
    object-fit: cover;
  }

  @keyframes profile-load-animation {
    from{
      width: 0;
      transform: scale(0.5);
    }
    to{
      transform: scale(1);
      width: 243px;
    }
  }

  @keyframes profile-pulse-animation {
    0%{
      border: 3px solid rgba(19, 196, 155, 0.175);
      box-shadow: 
      0 0 11px 1px rgba(0, 0, 0, 0.25),
      0 0 15px 0 rgba(69, 172, 163, 0.2);
    }
    50%{
      border: 3px solid rgba(143, 158, 156, 0.08);
      box-shadow: 
      0 0 11px 1px rgba(0, 0, 0, 0.25),
      0 0 25px 1px rgba(69, 172, 163, 0.02);
    }
    100%{
      border: 3px solid rgba(19, 196, 155, 0.175);
      box-shadow: 
      0 0 11px 1px rgba(0, 0, 0, 0.25),
      0 0 15px 0 rgba(69, 172, 163, 0.2);
    }
  }

  .hello-icon {
    position: absolute;
    width: 70px;
    z-index: 10;
    transform: translate(199px, -130px) scale(1);
    /*opacity: 0;
    animation: ease hello-icon-animation 1s 1.1s forwards; */
  }

  @keyframes hello-icon-animation {
    from{
        opacity: 0;
        transform: translate(-50px, -110px) scale(0);
    }
    to{
        opacity: 1;
        transform: translate(-50px, -110px) scale(1);
    }
  }

  .lime-title {
    color: rgb(10, 191, 128);
    font-size: 24px;
    font-weight: bold;
    letter-spacing: 2px;
  }

  .about {
    padding: 10px 0;
    display: flex;
    flex-direction: column;
  }

  .about span {
    display: inline;
    width: auto;
    color: rgb(10, 191, 128);
    font-weight: 400;
    font-size: 1.08em;
    cursor: pointer;
  }

  .iam {
    color: rgb(136, 134, 134);
  }

  .eyeIcon {
    width: 32vw;
    position: absolute;
    /* right: 0; */
  }

  .header-buttons {
    display: flex;
    gap: 15px;
    margin-top: 11px;
  }

  .secondary-btn,
  .primary-btn {
    width: 135px;
    padding: 11px 5px;
    border-radius: 8px;
    border: none;
    font-size: 1em;
    color: #d8d8d8c2;
    /* opacity: 0.7; */
    cursor: pointer;
    box-shadow: 0 0 5px rgba(10, 191, 128, 0);
    transition: 0.4s;
  }

  .secondary-btn {
    background-color: transparent;
    border: solid 1px #d8d8d8c2;
  }

  .primary-btn {
    background-color: #0abf80d5;
    color: #000000d5;
    font-weight: 500;
  }

  /*---------------- Desktop Only media query ----------------*/

  @media (min-width: 1024px) {
    .secondary-btn:hover {
      color: #d8d8d8;
      border: solid 1px #0abf80;
      transition: 0.2s;
    }
    
    .primary-btn:hover {
      background-color: #0abf80;
      color: black;
      box-shadow: 0 0 20px rgba(10, 191, 128, 0.5);
      transition: 0.2s;
    }
  }

  /*-------------- Tablet/Mobile media queries --------------*/

  @media (max-width: 1024px) {
    .secondary-btn:hover,.secondary-btn:focus {
      background-color: transparent;
      border: solid 1px #d8d8d8c2;
    }
    .primary-btn:hover,.primary-btn:focus {
      background-color: #0abf80d5;
      color: #000000d5;
      box-shadow: 0 0 5px rgba(10, 191, 128, 0);
    }

    .secondary-btn:active {
      color: #d8d8d8;
      border: solid 1px #0abf80;
      transition: 0s;
    }
    
    .primary-btn:active {
      background-color: #0abf80;
      color: black;
      box-shadow: 0 0 20px rgba(10, 191, 128, 0.5);
      transition: 0s;
    }


  }

  @media (max-width: 800px) {
    .header-header {
      margin: 0 25px 0 110px;
    }
  }

  @media (max-width: 590px) {
    .header-shadow {
      opacity: 0.95;
      background: linear-gradient(
        145deg,
        rgba(19, 22, 22, 0.95),
        rgba(19, 22, 22, 0.92) 52%,
        rgba(20, 20, 20, 0.86) 85%,
        rgba(24, 24, 24, 0.58) 100%
      );
    }
  }

  @media (max-width: 480px) {
    .header-header {
      margin: 0 25px 0 85px;
    }
    .header-body h1 {
      font-size: 7.5vw;
    }
    .header-body .lime-title {
      font-size: 5.2vw;
    }
  }
  
</style>
