<template>
    <div></div>
</template>

<script>
    export default {
        name: 'CursorEffectComponent',
        props: {
            inputString: {
                type: String,
                default: 'R.T.'
            },
            elSize: {
                type: Number,
                default: 20
            },
            elWordSeperator: {
                type: Object,
                default() {
                    return {el: '.', color: '#fff'}
                }
            },
            elDefaultColor: {
                type: String,
                default: '#0abf80'
            }
        },
        data() {
            return {
                interval: 0,
                elementIndex: 0,
                elClass: 'cursor-trail-icon'
            }
        },
        computed:{
            body: () => document.querySelector('body'),
            binaryMessage: function() {return this.stringToBinary(this.inputString)},
            seperatorIndexes: function() {
                //get seperator as binary
                let sepBinary = this.stringToBinary(this.elWordSeperator.el);

                //get binary as array for each char
                let messageBinaryArray = this.stringToBinary(this.inputString, true)
                // console.log(messageBinaryArray)

                let sepIndexes = [];
                let countIndexes = 0;

                //filter and return new array with all seperators indexes
                for(let i=0; i < messageBinaryArray.length; i++){

                    //if is seperator
                    if(sepBinary === messageBinaryArray[i]) {
                        for(let j=1; j < messageBinaryArray[i].length+1; j++) {
                            sepIndexes.push(countIndexes++)
                        }
                        countIndexes++
                    }else{
                        countIndexes += messageBinaryArray[i].length+1;
                    }
                }
                return sepIndexes;
            }
        },
        watch: {
            interval: function (val) {
                if(val > 1) this.elementIndex = 0; //if cursor stopped moving reset elementIndex to first element
            }
        },
        mounted() {
            window.addEventListener('mousemove', this.handleMouseMove); //initiate mouse movement
            setInterval(this.incrementInterval, 500); //increment interval every given miliseconds to make it known that cursor is not moving
        },  
        methods: {
            handleMouseMove(event) {
                if(localStorage.getItem('eyeActive') === 'true') {

                    this.interval = 0; //reset interval to make it known that cursor is moving

                    if(this.elementIndex >= this.binaryMessage.length) this.elementIndex = 0; //if elementIndex has reached the last element than reset to first element

                    this.createElement(event); //create cursor trail element
                }
            },
            createElement(event){
                //create cursor trail element
                let el = document.createElement('span');

                //set element position from cursor horizontal and vertical position in relation to viewport
                el.style.left = `${event.clientX}px`; el.style.top = `${event.clientY}px`;
                
                el.classList.add(this.elClass); //add specified class to element
                el.style.fontSize = `${this.elSize}px`; //set specified size to element
                el.style.color = this.currentColor(); //set current color
                
                el.innerText = this.binaryMessage[this.elementIndex++]; //set the current element

                this.body.appendChild(el); //display element

                //remove element after the given miliseconds 
                setTimeout(function(){
                    el.remove();
                }, 1900)
            },
            currentColor() {
                //if elementIndex has reached one of the seperators set seperator color else set default color
                return this.seperatorIndexes.includes(this.elementIndex) ? this.elWordSeperator.color : this.elDefaultColor;
            },
            incrementInterval() {this.interval++},
            // stringToBinary(input) {
            //     let binaryResult = ''; 
      
            //     for (let i = 0; i < input.length; i++) { 
            //         const charCode = input.charCodeAt(i); 
            //         let binaryValue = ''; 
                    
            //         for (let j = 7; j >= 0; j--) { 
            //             binaryValue += (charCode >> j) & 1; 
            //         } 
                    
            //         binaryResult += binaryValue + ' '; 
            //     } 
                
            //     return binaryResult.trim(); 
            // }
            
            stringToBinary(string, returnAsArray = false) {
                let binary = string.split('').map(function (char) {
                    return char.charCodeAt(0).toString(2);
                });

                if(returnAsArray) return binary
                else return binary.join(' ')
            }
        }
    }

</script>

<style>
    .cursor-trail-icon {
        position: fixed;
        /* display: inline-block; */
        pointer-events: none;
        /* width: 10px;
        height: 10px; */
        /* background: rgb(6, 228, 43); */
        z-index: 99999;
        animation: 1.8s icon-trail-animation ease-in-out;
        opacity: 0;
    }

    @keyframes icon-trail-animation {
        0%{
            opacity: 0;
            transform: translateY(0);
        }
        1.5%{
            transform: translateY(5px);
        }
        15%{
            opacity: 0.7;
        }
        100%{
            opacity: 0;
            transform: translateY(300px);
        }
    }
</style>