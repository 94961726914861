<template>
  <section id="skills" class="section">
        <h2>Skills</h2>
        <p>I'm very proficient with <b>HTML</b>, <b>CSS</b>, and <b>JavaScript</b>, along with several frontend frameworks such as <b>Vue</b> and <b>React</b>. I am able to create responsive and user-friendly interfaces, while always striving to create visually appealing designs that not only look good, but also enhance the overall user experience.</p>
        <p>In addition to frontend development, I also possess a strong knowledge in backend development. My experience includes working with <b>Node.js</b>, <b>PHP</b>, and <b>ASP.NET Core</b>, as well as databases like <b>MySQL</b>, <b>SQL Server</b>, and <b>MongoDB</b>.</p>
        
        <div class="section-content">
          <div class="skills-container">
          
            <div 
              :class="!isMobileOrTablet ? 'skills-card front-end-card wow animate__zoomInLeft' : 'skills-card front-end-card wow animate__fadeInUp'" 
              data-wow-duration="1s"
            >
              <div class="skills-card-head">
                <div class="card-head-img">
                  <img src="@/assets/img/icons/skills/webp/front-end-skills.webp" alt="Rilind Tasholli FrontEnd Skills"/>
                </div>
                <h3>Front-End</h3>
              </div>
  
              <div class="skills-card-body">
                <ul>
                  <li>HTML</li>
                  <li>CSS</li>
                  <li>JavaScript</li>
                  <li>Vue</li>
                  <li>React</li>
                </ul>
              </div>
            </div>
  
            <div 
              :class="!isMobileOrTablet ? 'skills-card back-end-card wow animate__zoomInLeft' : 'skills-card front-end-card wow animate__fadeInUp'" 
              data-wow-duration="1.25s"
            >
              <div class="skills-card-head">
                <div class="card-head-img">
                  <img src="@/assets/img/icons/skills/webp/back-end-skills.webp" alt="Rilind Tasholli BackEnd Skills"/>
                </div>
                <h3>Back-End</h3>
              </div>
  
              <div class="skills-card-body">
                <ul>
                  <li>PHP</li>
                  <li>NodeJS</li>
                  <li>ASP.NET Core</li>
                  <li>SQL</li>
                  <li>MongoDB</li>
                </ul>
              </div>
            </div>
  
            <div 
              :class="!isMobileOrTablet ? 'skills-card additional-card wow animate__zoomInLeft' : 'skills-card front-end-card wow animate__fadeInUp'" 
              data-wow-duration="1.55s"
            >
              <div class="skills-card-head">
                <div class="card-head-img">
                  <img src="@/assets/img/icons/skills/webp/additional-skills.webp" alt="Rilind Tasholli Additional Skills"/>
                </div>
                <h3>Additional</h3>
              </div>
  
              <div class="skills-card-body">
                <ul>
                  <li>Wordpress</li>
                  <li>SEO</li>
                  <li>Git</li>
                  <li>UI/UX</li>
                </ul>
              </div>
            </div>
  
            
          </div>
        </div>
       
      </section>
</template>

<script>
export default {
  computed: {
    isMobileOrTablet: () => window.innerWidth < 1024
  }
}
</script>

<style scoped>
  #skills {
   margin-top: 50px; 
  }
 .skills-container {
    display: flex;
    justify-content: space-around;
    align-items: stretch;
    margin-top: 100px;
    gap: 3vw;
  }

  .skills-card {
    width: 100%;
    position: relative;
    background-color: rgb(29, 31, 32);
    padding: 115px 0 60px 0;
    min-width: 324px;
    box-shadow: 0px 4px 15px 0px rgba(18, 18, 19, 0.25);
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    transition: .5s;
  }

  .skills-card-head {
    position: absolute;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 15px;
    top: -40px;
    left: 50%;
    transform: translateX(-50%);
  }

  .card-head-img {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgb(50, 56, 61);
    width: 80px;
    height: 80px;
    padding: 10px;
    border-radius: 50%;
    box-shadow: 0 4px 0 2px rgba(12, 13, 14, 0.1);
  }
  .skills-card-head img {
    height: 55%;
  }

  .skills-card-body {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .skills-card-body ul {
    /* border: dashed 1px red; */
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    list-style-type: none;
    /* margin: 77px 0 15px; */
  }

  .skills-card-body ul li {
    /* border: dashed 1px white; */
    padding: 4px 0;
    color: rgb(170, 170, 170);
    font-size: 1.05em;
    position: relative;
    text-align: left;
  }
  .skills-card-body ul li::before {
    content: '';
    position: relative;
    display: inline-block;
    background-image: url('@/assets/img/icons/other/webp/check-icon.webp');
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center bottom;
    /* background-color: red; */
    min-width: 20px;
    min-height: 20px;
    margin-right: 8px;
    font-size: 1.3em;
    color: rgb(10, 191, 128);
    font-weight: bold;
  }

  .back-end-card {
    transform: translateY(50px);
  }
  .additional-card {
    transform: translateY(100px);
  }

  /*-------------- Tablet/Mobile media queries --------------*/

  @media (max-width: 1300px) {
    .skills-container {
      gap: 10px;
    }
    .skills-card {
      min-width: unset;
    }
  }

  @media (max-width: 800px) {
    .skills-container {
      flex-direction: column;
    }
    .skills-card {
      min-width: unset;
    }
  
  }
  @media (max-width: 600px) {
    .card-head-img {
      width: 73px;
      height: 73px;
    }
  }

  
</style>