var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"section",attrs:{"id":"skills"}},[_c('h2',[_vm._v("Skills")]),_vm._m(0),_vm._m(1),_c('div',{staticClass:"section-content"},[_c('div',{staticClass:"skills-container"},[_c('div',{class:!_vm.isMobileOrTablet ? 'skills-card front-end-card wow animate__zoomInLeft' : 'skills-card front-end-card wow animate__fadeInUp',attrs:{"data-wow-duration":"1s"}},[_vm._m(2),_vm._m(3)]),_c('div',{class:!_vm.isMobileOrTablet ? 'skills-card back-end-card wow animate__zoomInLeft' : 'skills-card front-end-card wow animate__fadeInUp',attrs:{"data-wow-duration":"1.25s"}},[_vm._m(4),_vm._m(5)]),_c('div',{class:!_vm.isMobileOrTablet ? 'skills-card additional-card wow animate__zoomInLeft' : 'skills-card front-end-card wow animate__fadeInUp',attrs:{"data-wow-duration":"1.55s"}},[_vm._m(6),_vm._m(7)])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('p',[_vm._v("I'm very proficient with "),_c('b',[_vm._v("HTML")]),_vm._v(", "),_c('b',[_vm._v("CSS")]),_vm._v(", and "),_c('b',[_vm._v("JavaScript")]),_vm._v(", along with several frontend frameworks such as "),_c('b',[_vm._v("Vue")]),_vm._v(" and "),_c('b',[_vm._v("React")]),_vm._v(". I am able to create responsive and user-friendly interfaces, while always striving to create visually appealing designs that not only look good, but also enhance the overall user experience.")])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_vm._v("In addition to frontend development, I also possess a strong knowledge in backend development. My experience includes working with "),_c('b',[_vm._v("Node.js")]),_vm._v(", "),_c('b',[_vm._v("PHP")]),_vm._v(", and "),_c('b',[_vm._v("ASP.NET Core")]),_vm._v(", as well as databases like "),_c('b',[_vm._v("MySQL")]),_vm._v(", "),_c('b',[_vm._v("SQL Server")]),_vm._v(", and "),_c('b',[_vm._v("MongoDB")]),_vm._v(".")])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"skills-card-head"},[_c('div',{staticClass:"card-head-img"},[_c('img',{attrs:{"src":require("@/assets/img/icons/skills/webp/front-end-skills.webp"),"alt":"Rilind Tasholli FrontEnd Skills"}})]),_c('h3',[_vm._v("Front-End")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"skills-card-body"},[_c('ul',[_c('li',[_vm._v("HTML")]),_c('li',[_vm._v("CSS")]),_c('li',[_vm._v("JavaScript")]),_c('li',[_vm._v("Vue")]),_c('li',[_vm._v("React")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"skills-card-head"},[_c('div',{staticClass:"card-head-img"},[_c('img',{attrs:{"src":require("@/assets/img/icons/skills/webp/back-end-skills.webp"),"alt":"Rilind Tasholli BackEnd Skills"}})]),_c('h3',[_vm._v("Back-End")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"skills-card-body"},[_c('ul',[_c('li',[_vm._v("PHP")]),_c('li',[_vm._v("NodeJS")]),_c('li',[_vm._v("ASP.NET Core")]),_c('li',[_vm._v("SQL")]),_c('li',[_vm._v("MongoDB")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"skills-card-head"},[_c('div',{staticClass:"card-head-img"},[_c('img',{attrs:{"src":require("@/assets/img/icons/skills/webp/additional-skills.webp"),"alt":"Rilind Tasholli Additional Skills"}})]),_c('h3',[_vm._v("Additional")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"skills-card-body"},[_c('ul',[_c('li',[_vm._v("Wordpress")]),_c('li',[_vm._v("SEO")]),_c('li',[_vm._v("Git")]),_c('li',[_vm._v("UI/UX")])])])
}]

export { render, staticRenderFns }