<template>
  <section id="contact" class="section">
        <h2>Contact</h2>
        <p>Thank you for visiting my portfolio website. If you have any questions about my work, or if you're interested in discussing potential projects or collaborations, please don't hesitate to reach out. I'd be more than happy to chat with you!</p>
        <div class="section-content">
          <div 
            :class="!isMobileOrTablet ? 'contact-container wow animate__flipInX' : 'contact-container wow animate__zoomIn'"
            data-wow-duration="1.3s"
          >
            <div class="contact-left">
              <div class="empty-space"></div>
              <div class="contact-info">
                <div class="header-head">
                  <div class="profile">
                    <img src="@/assets/img/portraits/webp/rilind-tasholli-header-portrait.webp" alt="Rilind Tasholli Developer Contact">
                  </div>
                  <img class="lets-talk-icon" src="@/assets/img/icons/other/webp/lets-talk-icon.webp" alt="Lets Talk Chat Icon"/>
                </div>
                <a class="email" href="mailto:rilindtasholli@gmail.com" title="Send an email">rilindtasholli@gmail.com</a>
              </div>
              <ul class="socials-list">
                <li>
                  <a target="_blank" href="https://www.facebook.com/rilind.56" title="Visit Facebook Profile"><i class="fab fa-facebook"></i></a>
                </li>
                <li>
                  <a target="_blank" href="https://www.m.me/rilind.56" title="Contact via Messenger"><i class="fab fa-facebook-messenger"></i></a>
                </li>
                <li>
                  <a target="_blank" href="https://www.instagram.com/rilind_tasholli" title="Visit Instagram Profile"><i class="fab fa-instagram"></i></a>
                </li>
                <li>
                  <a target="_blank" href="https://www.linkedin.com/in/rilindtasholli" title="Visit LinkedIn Profile"><i class="fab fa-linkedin"></i></a>
                </li>
                <li>
                  <a target="_blank" href="https://www.github.com/rilindtasholli" title="Visit GitHub Profile"><i class="fab fa-github"></i></a>
                </li>
              </ul>
            </div>

            <div class="contact-right">
              <h3>Get in touch</h3>
              <!-- <p>You can directly send me an email or connect with me through the social links, or simply fill out the contact form below and I'll get back to you as soon as possible.</p> -->
              <p>You can directly send me an email, connect through the social media links, or simply fill out the contact form below and I'll get back to you as soon as possible.</p>

              <form 
                method="post" 
                @submit="handleSubmit"
                novalidate="true"
                name="contact"
              >

              <input type="hidden" name="form-name" value="contact-form" />


                <div class="input-group">
                  <div class="input-container">
                    <div class="input-field">
                      <input 
                        type="text" 
                        id="name"
                        v-model="name"
                        name="name" 
                        placeholder="Name" 
                        required
                        @change="removeError"
                        @keydown="removeError"
                      >
                      <label for="fullname">Name</label>
                    </div>
                    <p v-if="errors.name" class="input-error">{{ errors.name }}</p>
                  </div>

                  <div class="input-container email">
                    <div class="input-field">
                      <input 
                        type="email" 
                        id="email"
                        v-model="email"
                        name="email" 
                        placeholder="Email" 
                        required
                        @change="removeError"
                        @keydown="removeError"
                      >
                      <label for="email">Email</label>
                    </div>
                    <p v-if="errors.email" class="input-error">{{ errors.email }}</p>
                  </div>
                  </div>

                <div class="input-container">
                  <div class="input-field">
                    <textarea 
                      id="message" 
                      v-model="message"
                      name="message" 
                      cols="5" 
                      rows="5" 
                      placeholder="Message" 
                      required
                      @change="removeError"
                      @keydown="removeError"
                    ></textarea>
                    <label for="message">Message</label>                    
                  </div>
                  <p v-if="errors.message" class="input-error">{{ errors.message }}</p>
                </div>

                <div v-if="!formSubmitError" class="form-submit">
                  <button v-if="!loading" type="submit">Send</button>
                  <button v-else class="submit-loading-btn" disabled><img rel="preload" src="@/assets/img/dots-loading-animation.svg"/></button>
                </div>

                <div v-if="formSubmitError" class="submit-error">
                  <p>[ Message Failed ] <br> There was an error trying to send your message.<br>Please try again later or use different methods of contact.</p>
                  <a @click="removeError" class="try-again-btn">Try Again?</a>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>
</template>

<script>
export default {
  data() {
    return {
      errors: {
        name: '',
        email: '',
        message: ''
      },
      name: '',
      email: '',
      message: '',
      loading: true,
      formSubmitError: false,
    }
  },
  mounted() {
    //loading is set to true at first to preload the loading animation svg
    //when component is mounted set loading to false
    this.loading = false
  },
  computed: {
    isMobileOrTablet: () => window.innerWidth < 1024
  },
  methods: {
    handleSubmit(e) {
      e.preventDefault();

      this.formSubmitError = false

      if(this.validateForm()){
        const myForm = e.target;
        const formData = new FormData(myForm);
        
        fetch("/", {
          method: "POST",
          headers: { "Content-Type": "application/x-www-form-urlencoded" },
          body: new URLSearchParams(formData).toString(),
        })
        .then(() => {this.$router.push('/thank-you/')})
        .catch((error) => { 
          this.loading = false
          console.error(error)
          this.formSubmitError = true
        });
      }
    },
    validateForm() {
      this.errors = {
        name: '',
        email: '',
        message: ''
      };

      if (!this.name) {
        this.errors.name = "Name required";
      }
      if (!this.email) {
        this.errors.email = 'Email required';
      } else if (!this.validEmail(this.email)) {
        this.errors.email = 'Valid email required';
      }
      if (!this.message) {
        this.errors.message = "Message required";
      }

      const {name, email, message} = this.errors;

      if (!name && !email && !message) {
        this.loading = true
        return true
      } else {
        return false
      }
    },
    validEmail: function (email) {
      var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(email);
    },
    removeError: function(e) {
      const inputName = e.target.name
      // this.errors = {
      //   name: '',
      //   email: '',
      //   message: ''
      // }
      this.errors[inputName] = '' 

      this.formSubmitError = false
    }
  }
}
</script>

<style scoped>

#contact {
  margin-bottom: 60px;
}

.header-head {
  display: flex;
  align-items: center;
  justify-content: center;
  /* background-color: red; */
}

.profile {
  width: 185px;
  height: 185px;
  margin: 15px 15px 15px 0;
  border-radius: 50%;

  animation: 
  ease profile-load-animation 1s 0.6s forwards,
  linear profile-pulse-animation 2.3s infinite;
}

.profile img{
  width: 100%;
  height: 100%;
  border-radius: 50%;
  border: solid 8px transparent;
  object-position: center;
  object-fit: cover;
}

@keyframes profile-pulse-animation {
  0%{
    border: 3px solid rgba(19, 196, 137, 0.15);
    box-shadow: 
    0 0 11px 1px rgba(0, 0, 0, 0.25),
    0 0 8px 0 rgba(69, 172, 163, 0.1);
  }
  50%{
    border: 3px solid rgba(143, 158, 156, 0.07);
    box-shadow: 
    0 0 11px 1px rgba(0, 0, 0, 0.25),
    0 0 15px 1px rgba(69, 172, 163, 0.02);
  }
  100%{
    border: 3px solid rgba(19, 196, 137, 0.15);
    box-shadow: 
    0 0 11px 1px rgba(0, 0, 0, 0.25),
    0 0 8px 0 rgba(69, 172, 163, 0.1);
  }
}

.lets-talk-icon {
  position: absolute;
  width: 95px;
  z-index: 10;
  transform: translate(103px, -106px) scale(1);
  /*opacity: 0;
  animation: ease lets-talk-icon-animation 1s 1.1s forwards; */
}

.contact-container {
  /* border: dashed 2px rgb(0, 102, 255); */
  background-color: #1d1f20;
  display: flex;
  gap: 100px;
  /* max-width: 55vw; */
  /* margin-right: 6vw; */
  margin: 50px 0;
  max-width: 1040px;
  box-shadow: 0px 4px 15px 0px rgba(18, 18, 19, 0.25);
}

.contact-left {
  display: flex;
  flex-direction: column;
  background-color: #0abf80;
  width: 25%;
}

.contact-info {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #202527;
  min-width: 125%;
  padding: 40% 0;
  box-shadow: 0 0 8px rgba(18, 18, 19, 0.25);
  /* border: dashed yellow 1px; */
}

.contact-info .email {
  color: rgb(112, 170, 197);
  text-decoration: none;
  font-size: .908em;
  margin-top: 10px;
}
.contact-info .email::before {
  font-family: 'Font Awesome 5 Free';
  content: '\f0e0';
  margin-right: 6px;
  color: #0abf80;
}

.socials-list {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  list-style-type: none;
  padding: 0 10px;
  /* border: dashed red 1px; */
}

.socials-list, .empty-space {
  min-height: 60px;
  height: 100%;
}

.socials-list li {
  border-radius: 50%;
  cursor: pointer;
  transition: .55s;
}

.socials-list li a {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  padding: 10px;
  color: #1d1f20;
  text-decoration: none;
}

.contact-right {
  /* border: dashed 1px green; */
  display: flex;
  flex-direction: column;
  padding: 50px 65px 50px 25px;
}

.contact-right h3 {
  font-size: 1.6em;
  text-align: start;
  margin-bottom: 15px;
}

.contact-right h3:after {
  content: '.';
  font-size: 1.5em;
  color: #0abf80;
  margin: 0 3px;
}
.contact-right p {
  margin: 0;
}

.contact-right form {
  display: flex;
  flex-direction: column;
  margin-top: 27px;
  /* width: 65%; */
  /* align-self: center; */
}

.input-container {
  /* border: dashed 1px red; */
  width: 100%;
  font-size: 16px;
  margin-bottom: 18px;
}

.input-field {
  position: relative;
}

.input-container.email {
  width: 125%;
}

.input-field input,
.input-field input:focus,
.input-field textarea,
.input-field textarea:focus {
  font-size: 16px;
  padding: 18px 20px 12px;
  outline: none;
  border: none;
  border-radius: 5px;
  color: rgb(187, 186, 186);
  background-color: #262d30;
  width: 100%;
  resize: vertical;
}

.input-field textarea {min-height: 105px; max-height: 250px;}

.input-field label {
  position: absolute;
  left: 20px;
  font-size: inherit;
  color: rgba(255, 255, 255, 0.329);
  cursor: text;
  transition: top .3s;
  top: 50%;
  transform: translateY(-50%);
  pointer-events: none;
}
.input-field textarea + label{
  top: 25px;
}

.input-field input:focus + label,
.input-field input:valid + label,
.input-field input:not(:placeholder-shown) + label,
.input-field textarea:focus + label,
.input-field textarea:valid + label {
  color: white;
  top: -2px;
  font-size: 0.85em;
  letter-spacing: 1px;
  line-height: 1.5em;
  border-bottom: solid 2px #0abf80;
}

.input-field input::placeholder,
.input-field textarea::placeholder {
  color: transparent;
}

.input-group{
  display: flex;
  gap: 16px;
}

.input-container .input-error {
  text-align: left;
  font-size: 0.9em;
  margin-top: 10px;
  margin-left: 4px;
  color: rgb(223, 187, 187);
}
.input-error:before,
.submit-error p:before {
  font-family: 'Font Awesome 6 Free';
  content: '\f06a';
  font-weight: 600;
  margin-right: 5px;
  color: rgb(209, 130, 130);
}

.form-submit button {
  position: relative;
  margin-top: 10px;
  background-color: #0abf80d5;
  border: none;
  padding: 10px;
  width: 100%;
  height: 45px;
  border-radius: 5px;
  font-size: 15px;
  font-weight: bold;
  letter-spacing: 1px;
  box-shadow: 0 0 5px rgba(10, 191, 128, 0);
  transition: .3s;
}
.form-submit button:not(.submit-loading-btn):hover {
  background-color: #0abf80;
  box-shadow: 0 0 15px rgba(10, 191, 128, 0.5);
  cursor: pointer;
}

.submit-loading-btn img {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 35px;
}

form .submit-error p {
  text-align: center;
  padding: 10px 5px;
  border: solid 1px rgba(209, 130, 130, 0.356);
  border-radius: 5px;
}
.submit-error p:before {margin-right: 10px}

form .submit-error .try-again-btn {
  display: inline-block;
  font-size: 1em;
  background: none;
  border: none;
  color: rgba(112, 170, 197, 0.61);
  margin-top: 15px;
  cursor: pointer;
  transition: .1s;
}
form .submit-error .try-again-btn:hover {
  color: rgb(123, 187, 218);
}

/* --- remove white background when input autofill --- */
input:-webkit-autofill,
input:-webkit-autofill:focus {
    transition: background-color 600000s 0s, color 600000s 0s;
}
input[data-autocompleted] {
    background-color: transparent !important;
}
/* --------------------------------------------------- */

  /*---------------- Desktop Only media query ----------------*/

  @media (min-width: 1024px) {
    .socials-list li:hover {
      background-color: rgba(80, 230, 180, 0.61);
      transition: .3s;
    }
  }


  /*-------------- Tablet/Mobile media queries --------------*/

@media (max-width: 800px) {
  .contact-container {
    flex-direction: column;
    gap: 0;
  }

  .contact-left {
    width: 100%;
  }

  .contact-info {
    min-width: 100%;
    padding: 65px 0 45px;
  }

  .contact-right {
    padding: 50px 25px 50px 25px;
  }

  .contact-right h3 {
    text-align: center;
  }

  .contact-right p {
    text-align: center;
    margin-bottom: 15px;
  }

  .socials-list li:active {
      background-color: rgba(80, 230, 180, 0.61);
      transition: 0.05s;
    }
}

@media (max-width: 550px) {
  .input-group {
    flex-direction: column;
    gap: 0;
  }

  .input-container.email {
    width: 100%;
  }
}

</style>