<template>
    <div class="particles">
      <div id="particles-js"></div>
    </div>
</template>
  
  <script>
  export default {
    mounted() {
      this.initParticles()
    },
    methods: {
      initParticles() {
        window.particlesJS("particles-js", {
                  "particles": {
                      "number": {
                          "value": 60,
                          "density": {
                              "enable": true,
                              "value_area": 870
                          }
                      },
                      "color": {
                          "value": "#0abf80"
                      },
                      "shape": {
                          "type": "circle",
                          "stroke": {
                              "width": 0.2,
                              "color": "#bbb"
                          },
                          "polygon": {
                              "nb_sides": 5
                          },
                      },
                      "opacity": {
                          "value": 1,
                          "random": false,
                          "anim": {
                              "enable": false,
                              "speed": 1,
                              "opacity_min": 0.1,
                              "sync": false
                          }
                      },
                      "size": {
                          "value": 4,
                          "random": false,
                          "anim": {
                              "enable": false,
                              "speed": 40,
                              "size_min": 2.5,
                              "sync": false
                          }
                      },
                      "line_linked": {
                          "enable": true,
                          "distance": 225,
                          "color": "#ffffff",
                          "opacity": 0.6,
                          "width": 1.2
                      },
                      "move": {
                          "enable": true,
                          "speed": 2.3,
                          "direction": "none",
                          "random": false,
                          "straight": false,
                          "out_mode": "out",
                          "bounce": false,
                          "attract": {
                              "enable": false,
                              "rotateX": 600,
                              "rotateY": 1200
                          }
                      }
                  },
                  "interactivity": {
                      "detect_on": "canvas",
                      "events": {
                          "onhover": {
                                  "enable": false,
                                  "mode": "grab"
                          },
                          "onclick": {
                              "enable": false,
                              "mode": "push"
                          },
                          "resize": true
                      },
                      "modes": {
                          "grab": {
                              "distance": 140,
                              "line_linked": {
                                  "opacity": 1
                              }
                          },
                          "bubble": {
                              "distance": 400,
                              "size": 40,
                              "duration": 2,
                              "opacity": 8,
                              "speed": 3
                          },
                          "repulse": {
                              "distance": 200,
                              "duration": 0.4
                          },
                          "push": {
                              "particles_nb": 4
                          },
                          "remove": {
                              "particles_nb": 2
                          }
                      }
                  },
                  "retina_detect": true
              })
      }
    }
  };
  </script>
  
  <style scoped>
  #particles-js {
    /* background: black; */
    height: 100%;
    width: 100%;
  }
  </style>
  