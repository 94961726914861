<template>
  <div class="eye">
        <div class="outter">
            <div class="eye-lid"><span></span></div>
            <div class="inner">
                <div class="sclera">
                    <div class="iris">
                        <div class="glow"></div>
                        <div class="pupil"></div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'EyeComponent',
        data() {
            return {
                pointerY: '',
                pointerX: '',
                width: '',
                height: '',
                cursorPosition: '',
                seconds: 0
            }
        },
        computed: {
            eye: () => document.querySelector('.iris'),
            pupil: () => document.querySelector('.pupil')
        },
        mounted() {

            if(window.innerWidth < 1024) {
                //if device is laptop or mobile disable eye active
                localStorage.setItem('eyeActive', false)
            }

            document.addEventListener('mousemove', (event) => {
                this.pointerX = event.clientX
                this.pointerY = event.clientY

                localStorage.getItem('eyeActive') === 'true' ? this.seconds = 3 : this.seconds = -1
            })

            setInterval(() => {
                this.seconds--
            }, 1000);

   
            
            setInterval(()=>{
                if(this.seconds > 0){
                    //if cursor is moving

                    document.querySelector('body').style.cursor = 'auto'; //show cursor

                    //if cursor eye tracking is active 
                    if(localStorage.getItem('eyeActive') === 'true'){
                        this.pupil.classList.add('pupil-focus') //shrink pupil (add focus)
                        this.eye.style.animation = 'none' //remove animation
                        this.followCursor() //start following mouse cursor
                    }
                  
                }else{
                    //if cursor is NOT moving
                    
                    document.querySelector('body').style.cursor = localStorage.getItem('eyeActive') === 'true' ? 'none' : 'auto' //if cursor eye tracking is active hide cursor, else show
                    
                    //if 3+1 seconds have passed
                    if(this.seconds <= -1) {
                        this.eye.style.animation = null; //reset animation to default
                        this.pupil.classList.remove('pupil-focus') //extend pupil (remove focus)   
                    }
                }
            }, 1);
        },  
        methods: {
        getWindowsDimensions() {
            if(window.innerWidth !== undefined && window.innerHeight !== undefined) { 
                this.width = window.innerWidth
                this.height = window.innerHeight
            }else {   
                this.width = document.documentElement.clientWidth
                this.height = document.documentElement.clientHeight
            }
        },
        getCursorAreaPosition() {

            this.getWindowsDimensions()

            var Ypercentage = (this.pointerY/this.height) * 100
            var Xpercentage = (this.pointerX/this.width) * 100

            var verticalPosition = ''
            var horizontalPosition = ''

            //get verticalPosition
            if(Ypercentage <= 20){
                verticalPosition = 'top'
            }else if(Ypercentage <= 60){
                verticalPosition = 'middle'
            }else{
                verticalPosition = 'bottom'
            }

            //get horizontalPosition
            if(Xpercentage <= 75){
                horizontalPosition = 'left'
            }else if(Xpercentage <= 89){
                horizontalPosition = 'middle'
            }else{
                horizontalPosition = 'right'
            }

            // example 'top-left'
            this.cursorPosition = verticalPosition + '-' + horizontalPosition
        },
        followCursor() {
            //get position example: 'top-left'
            this.getCursorAreaPosition()

            var perspective = 'perspective(400px)';
            var translate = '';
            var rotateX = '';
            var rotateY = '';


            if(this.cursorPosition == "top-left"){
                //Top-Left
                translate = 'translate(-18%, -14%)'
                rotateX = 'rotateX(7deg)'
                rotateY = 'rotateY(-10deg)'
                // console.log('cursor is on top-left')
            }else if(this.cursorPosition == 'top-middle'){
                //Top-Middle
                translate = 'translate(0, -15%)'
                rotateX = 'rotateX(3deg)'
                rotateY = 'rotateY(0)'
            }else if(this.cursorPosition == 'top-right'){
                //Top-Right
                translate = 'translate(13%, -14%)'
                rotateX = 'rotateX(6deg)'
                rotateY = 'rotateY(8deg)'
            }else if(this.cursorPosition == 'middle-right'){
                //Middle-Right
                translate = 'translate(23%, 0)'
                rotateX = 'rotateX(0)'
                rotateY = 'rotateY(13deg)'
            }else if(this.cursorPosition == 'bottom-right'){
                //Bottom-Right
                translate = 'translate(22%, 12%)'
                rotateX = 'rotateX(-10deg)'
                rotateY = 'rotateY(13deg)'
            }else if(this.cursorPosition == 'bottom-middle'){
                //Bottom-Middle
                translate = 'translate(0, 13%)'
                rotateX = 'rotateX(-10deg)'
                rotateY = 'rotateY(0)'
            }else if(this.cursorPosition == 'bottom-left'){
                //Bottom-Left
                translate = 'translate(-15%, 13%)'
                rotateX = 'rotateX(-10deg)'
                rotateY = 'rotateY(-10deg)'
            }
            else if(this.cursorPosition == 'middle-left'){
                //Middle-Left
                translate = 'translate(-22%, 0)'
                rotateX = 'rotateX(0)'
                rotateY = 'rotateY(-13deg)'
            }

            
            this.eye.style.transform =  `${perspective} ${translate} ${rotateX} ${rotateY}`

        }
    }
}

</script>

<style scoped>
    .eye {
        /* padding: 180px 350px; */
        width: 32.3vw;
        height: 32vw;
        /* border: solid 1px red; */
        display: flex;
        justify-content: center;
        align-items: center;
        /* opacity: 1; */
        position: fixed;
        top: 0;
        right: 2vw;
        /* filter: blur(1px); */
        margin: 0 50px;
    }


    .outter{
        width:70%;
        height:70%;
        border-radius: 75% 0%;
        overflow: hidden;
            
        border-top: solid 1.15vw #4a4d52;
        border-left: solid 1.15vw #4a4d52;
        border-right: solid 0.9vw #5f6364;
        border-bottom: solid 0.9vw #5f6364;

        transform: rotate(40deg);
        background-color: aliceblue;

        box-shadow: 2px 4px 35px 20px rgb(18, 22, 20);
    }

    .inner{
        width: 100%;
        height: 100%;
        position: absolute;
        /* border: solid 1px rgb(185, 33, 190); */
        transform: rotate(-40deg);
    }

    .eye-lid{
        width: 150%;
        height: 100%;
        position: absolute;
        top: 50%;
        left: 50%;
        /* background-color: rgb(24, 24, 24); */
        z-index: 6;
        transition: 0.25s;

        /* border: solid 1px rgb(238, 83, 36); */

        transform: translate(-50%, -50%) rotate(-40deg);
    }

    .eye-lid span{
        width: 100%;
        height: 0;
        left: 0;
        position: absolute;
        background-color: rgb(43, 44, 44, 1);
        transition: 0.5s;
        /* animation: blink-animation 20s infinite alternate; */
    }

    .eye:hover .eye-lid span{
        height: 100%;
    }

    @keyframes blink-animation{
        0%{
            height: 0;
        }
        1%{
            height: 100%;
        }
        3%{
            height: 0;
        }

        25%{
            height: 0;
        }
        26%{
            height: 100%;
        }
        27%{
            height: 0;
        }

        67%{
            height: 0;
        }
        68%{
            height: 100%;
        }
        69%{
            height: 0;
        }

        98%{
            height: 0;
        }
        99%{
            height: 100%;
        }
        100%{
            height: 0;
        }
    }

    .sclera{
        width: 100%;
        height: 100%;
        /* background: linear-gradient( 140deg, rgb(238, 238, 238), rgb(162, 167, 167)); */
        /* background: radial-gradient(circle, rgba(255,255,255,1) 50%, rgb(192, 192, 192) 100%); */
        display: flex;
        justify-content: center;
        align-items: center;
    
        /* border-radius: 50%; */
        /* overflow: hidden; */
        /* animation: sclera-animation 2s ease infinite; */
    }


    .iris{
        width: 65%;
        height: 80%;
        /* background: linear-gradient( 140deg, rgb(15, 145, 101), rgb(39, 143, 184)); */
        background: radial-gradient(circle, rgba(19,182,138,1) 25%, rgba(17,99,131,1) 100%);
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        /* border: solid 5px rgba(0, 0, 0, 0.1); */
        box-shadow: inset -4px -15px 25px 4px rgba(0, 0, 0, 0.3);
        animation: iris-animation 6.5s ease-in-out infinite alternate;

        /* transform: 
        perspective(400px) translate(-15%, -15%) rotateX(-15deg); */

        transition: 0.75s;
    }

    @keyframes iris-animation {
        0%{
            /* transform: 
                translateY(-12%) 
                translateX(0)
                perspective(400px) 
                rotateY(0deg)
                rotateX(0deg); */
        }
        /*------ top-left ------*/
        16.6%{
            transform: 
                translateY(-15%)
                translateX(-20%)
                perspective(400px) 
                rotateY(-14deg)
                rotateX(7deg);
        }
        21.6%{
            transform: 
                translateY(-15%)
                translateX(-20%)
                perspective(400px) 
                rotateY(-14deg)
                rotateX(7deg);
        }
        /*------ center ------*/
        30.2%{
            transform: 
                translateY(-10%) 
                translateX(0)
                perspective(400px) 
                rotateY(0deg)
                rotateX(2deg);
            
        }
        38.2%{
            transform: 
                translateY(-10%) 
                translateX(0)
                perspective(400px) 
                rotateY(0deg)
                rotateX(2deg);
            
        }
        /*------ bottom-right ------*/
        49.8%{
            transform: 
                translateY(5%)
                translateX(15%)
                perspective(400px) 
                rotateY(6deg)
                rotateX(-8deg);
            
        }
        /*------ left(slightly-bottom) ------*/
        64.4%{
            transform: 
                translateY(5%)
                translateX(-18%)
                perspective(400px) 
                rotateY(-15deg)
                rotateX(-5deg);
            
        }
        72.4%{
            transform: 
                translateY(5%)
                translateX(-18%)
                perspective(400px) 
                rotateY(-15deg)
                rotateX(-5deg);
            
        }
        /*------ left(slightly-bottom (more bottom and less left))) ------*/
        83%{
            transform: 
            translateY(10%)
            translateX(-8%)
            perspective(400px)
            rotateY(-7deg)
            rotateX(-10deg);
            
        }
        /*------ center ------*/
        100% {
            transform: 
            translateY(-8%) 
            translateX(0)
            perspective(400px)
            rotateY(0)
            rotateX(0);
        }
    }
    .pupil{
        width: 43%;
        height: 43%;
        background: linear-gradient( 140deg, rgb(26, 26, 26), rgb(14, 14, 14));
        border-radius: 50%;
        box-shadow: 
            0px 0px 17px 4px rgba(218, 73, 6, 0.55),
            inset 0px 0px 5px 2px rgba(158, 158, 158, 0.3);
        /* animation: pupil-animation 4s ease infinite alternate; */
        transition: 0.8s;
    }

    .pupil-focus{
        width: 35%;
        height: 35%;
    }


    .glow{
        width: 25%;
        height: 20%;
        background: linear-gradient( 140deg, rgb(236, 236, 236) 10%, rgba(161, 161, 161, 0) 65%);
        position: absolute;
        /* z-index: 5; */
        border-radius: 50%;
        transform: translate(-35%, -50%);
        /* animation: glow-animation 4s ease infinite; */
    }

    
    /*-------------- Tablet/Mobile media queries --------------*/

    @media (max-width: 1024px) {

        .eye{
            width: 37.3vw;
            height: 37vw;
            top: 100px;
            margin: 0 15px;
            bottom: 110px;
            right: 0;
        }
    }

    @media (max-width: 590px) {

        .eye{
            width: 45.3vw;
            height: 45vw;
            margin: 0 15px;
            top: 580px;
            right: 0;
        }
    }


    @media (max-width: 480px) {

        .eye{
            top: 645px;
        }
    }

</style>